  .asio-checkout-save__button > span {
    @apply justify-center flex flex-col items-center;
    &::after {
      @apply flex content-[''] bg-link hover:bg-preheading h-[2px] w-[30px] mt-0.5;
    }
  }

  .asio-step-header-container {
    @apply flex justify-between items-center my-8;
  }

  .asio-step-title-container {
    @apply flex flex-col space-y-2;
  }

  .asio-step-title-text {
    @apply font-poppins text-[28px] leading-10 text-body font-semibold;
  }

  .asio-step-title-container__disabled > .asio-step-title-text {
    @apply !text-gray-300;
  }

  .asio-step-title-container__disabled > .asio-divider > div {
    @apply !bg-gray-400;
  }

  .asio-form-title {
    @apply font-poppins text-[22px] leading-9 font-medium text-body;
  }

  .asio-shipping-panel__container {
    @apply flex items-center space-x-2 border-bcolor border rounded-md p-4;
  }

  .asio-shipping-panel__shipping-method {
    @apply flex flex-row justify-between items-center w-full font-normal text-body font-poppins md:text-[14px] md:leading-[22px] text-[16px] leading-[26px];
  }

  .asio-review-container {
    @apply flex flex-col space-y-4 items-start w-full ml-6 md:mb-10 mb-6;
  }

  .asio-payment-container {
    @apply flex flex-col space-y-6 items-start w-full;
  }

  .asio-place-order__button > span {
    @apply justify-center flex flex-col items-center;
    &::after {
      @apply flex content-[''] bg-gray-300 h-[2px] w-[30px] mt-0.5;
    }
  }

  .asio-payment-card-container {
    @apply mt-4 transition-all duration-150 ease-in-out flex flex-col w-full;
  }
  
  .asio-shipping-details__container {
    @apply flex md:w-1/3 flex-col md:ml-6 ml-3 w-full;
  }

  .asio-address-details-container {
    @apply flex items-start md:gap-x-8 md:ml-6 ml-3 md:flex-row flex-col;
  }

  .asio-address-details__text-container {
    @apply flex items-start md:gap-x-2 w-full md:flex-row flex-col gap-y-6;
  }

  .asio-address-details__text {
    @apply flex flex-col md:w-1/3 w-full font-poppins text-body;
  }

  .asio-payment-suggestion {
    @apply flex flex-col space-y-4 w-max rounded-xl p-4 bg-yellow-100 border border-yellow-600 font-poppins text-yellow-700;
  }

  .asio-payment-suggestion__data-content {
    @apply flex flex-row space-x-6 items-center text-xs;
  }

  .asio-payment-suggestion__data-item {
    @apply flex flex-row gap-2;
  }