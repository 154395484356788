/* Add To Cart Button */

.asio-add-to-cart-buttons__container {
  @apply ml-4 mt-8 flex items-center space-x-4;
}

.asio-add-to-cart-button {
  @apply rounded-md p-4;
}

/* Update Items Cart */

.asio-update-quantity-item-cart-button {
  @apply h-2 w-2 bg-foreground;
}

.asio-update-item-cart__value {
  @apply px-1;
}

.asio-update-quantity-item-cart__container {
  @apply flex flex-row items-center gap-4;
}

.asio-remove-quantity-item-cart {
  @apply flex h-10 w-10 items-center justify-center rounded-md;
}

.asio-add-quantity-item-cart {
  @apply flex h-10 w-10 items-center justify-center rounded-md;
}

/* Remove Item button */

.asio-cart-remove-item-button {
  @apply flex items-center justify-center rounded-[0.375rem] bg-body-foreground p-[0.75] text-white;
}

/* Discount Code */

.asio-discount-code__container {
  @apply flex justify-between gap-2;
}

.asio-discount-code-input__container {
  @apply flex flex-col gap-1;
}

.asio-discount-code__input {
  @apply w-[100px] rounded-md border-bcolor;
}

.asio-discount-code-input__hint {
  @apply mt-0 text-[12px] font-light text-gray-500;
}

.asio-remove-discount-code__button {
  @apply h-10 w-12 bg-body p-1;
}

/* Cart Item */
.asio-cart-item__container {
  @apply w-full flex flex-row justify-between p-3 border border-bcolor rounded-xl relative;
}

.asio-cart-item__container__deleted {
  @apply opacity-0 transition duration-300 ease-in;
}

.asio-cart-item__details {
  @apply flex flex-row md:items-center justify-start md:space-x-4 space-x-2 items-start;
}

.asio-cart-item__details-image-container {
  @apply md:w-48 flex flex-col items-center space-y-2 md:flex-row;
}

.asio-cart-item__details-image {
  @apply h-36 w-full object-cover object-center rounded-xl;
}

.asio-cart-item__title {
  @apply capitalize font-poppins text-[18px] md:leading-7 md:text-[24px] leading-6 font-semibold text-body;
}