.asio-message-box__container {
    @apply flex flex-col gap-8 md:w-1/2 md:my-14 w-full my-8;
}

.asio-message-box__header {
    @apply flex flex-col gap-2 text-body font-semibold font-poppins;
}

.asio-message-box__body {
    @apply flex flex-col md:gap-8 gap-6 text-body font-poppins;
}

.asio-message-box__content-order-details {
    @apply flex flex-col gap-2;
}

.asio-message-box__content-summary {
    @apply flex flex-col gap-4;
}

.asio-message-box__content-title {
    @apply mb-2;
}

.asio-message-box__content-summary-details {
    @apply flex flex-col gap-4 border-t py-4;
}

.asio-message-box__content-summary-details > div {
    @apply flex flex-row justify-between items-center;
}

.total-order__text {
    @apply py-4 border-y flex flex-row justify-between items-center font-bold text-xl;
}

.asio-message-box__content-delivery,
.asio-message-box__content-payment {
    @apply flex flex-col gap-4;
}

.asio-message-box__content-delivery-container,
.asio-message-box__content-payment-container {
    @apply flex flex-col gap-4 md:gap-0 md:flex-row justify-between;
}

.asio-message-box__content-footer {
    @apply flex flex-col gap-6 border-t pt-4;
}

.asio-message-box__content-footer-links {
    @apply flex flex-row justify-between text-gray-400 text-sm;
}

.asio-message-box__content-footer-links > a {
    @apply hover:underline hover:text-link;
}

.asio-message-box__content-footer-btns__container {
    @apply flex flex-row justify-between items-center;
}

.asio-message-box__content-footer-btns {
    @apply flex flex-row gap-2 items-center text-divider uppercase font-poppins font-semibold text-sm;
}
