@import url("./ecommerce/product-details.css");
@import url("./ecommerce/cart.css");
@import url("./ecommerce/product-card.css");
@import url("./ecommerce/checkout.css");
@import url("./ecommerce/summary.css");
@import url("./ecommerce/thank-you.css");
@import url("./ecommerce/account.css");
@import url("landing.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-bcolor;
  }
  body {
    @apply bg-background text-foreground;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --bcolor: 0 0% 92.5%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;

    --divider: 329 82% 22%;

    --body: 234 63% 11.9%;
    --body-foreground: 233 16% 29%;

    --link: 233 96% 67%;

    --ariesblue: 234 55% 36%;

    --primarybtn: 327 66% 43%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --bcolor: 0 0% 83.1%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;

    --radius: 0.5rem;

    --divider: 329 82% 22%;

    --body: 234 63% 12%;
    --body-foreground: 233 17% 29%;

    --link: 233 96% 67%;

    --ariesblue: 234 55% 36%;

    --primarybtn: 327 66% 43%;
  }
}

@layer components {
  .asio-footer-panel {
    @apply flex flex-col;
  }

  .asio-footer h6 {
    @apply text-white py-2 font-poppins font-semibold text-[22px] leading-[30px] md:text-[18px] md:leading-[22px];
  }

  .asio-footer-link {
    @apply text-white py-3 text-[14px] leading-[22px] font-poppins;
    &:hover {
      @apply underline;
    }
  }

  .asio-footer-email {
    @apply text-white font-semibold font-poppins;
  }

  .submit-btn-label {
    @apply flex flex-row justify-between items-center;
  }

  .asio-divider {
    @apply flex;
  }

  .asio-divider > div {
    @apply h-[2px] w-[30px] bg-divider;
  }

  .asio-section-title {
    @apply text-body font-poppins font-semibold text-[22px] leading-9 md:text-[48px] md:leading-[60px];
  }

  .asio-sheet-header {
    @apply flex pb-2 flex-row justify-between items-center text-left;
  }

  .asio-sheet-title {
    @apply font-poppins text-[22px] leading-9 font-semibold text-body;
  }

  .asio-sheet-description {
    @apply text-sm text-muted-foreground;
  }

  .asio-sheet-primitive-close {
    @apply absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary;
  }

  .asio-sheet-close {
    @apply absolute right-4 top-4 opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none data-[state=open]:bg-secondary;
  }

  .asio-sheet-content {
    @apply flex flex-col justify-between flex-1;
  }

  .asio-sheet-footer {
    @apply flex flex-col w-full border-t pt-6 space-y-4;
  }

  .details-title {
    @apply font-semibold;
  }

  .asio-account-addresses__button {
    @apply justify-center flex flex-col items-center;
    &::after {
      @apply flex content-[''] bg-gray-300 h-[2px] w-[30px] mt-0.5;
    }
  }
}
