/* Product Details */

.asio-product-title__container {
  @apply flex flex-row md:items-center justify-between mt-6 md:mx-0 mx-6;
}

.asio-product-title {
  @apply font-poppins ml-0 font-semibold md:text-[28px] text-[18px] leading-7 md:leading-[42px] text-body capitalize;
}

.asio-product-title__price {
  @apply md:text-[28px] text-[18px] leading-7 md:leading-[42px];
}

.asio-product-price__container {
  @apply flex flex-row;
}

.asio-product-tile__price-container {
  @apply flex flex-col mt-0 items-end text-body;
}

.asio-product-price__value {
  @apply font-light font-poppins text-gray-400 line-through;
}

.asio-product__tag {
  @apply text-white rounded-sm font-poppins font-bold uppercase bg-body text-[10px] px-1;
}

.asio-product-tile {
  @apply flex p-2 border border-bcolor rounded-md bg-white;
}

.asio-product-tile__disabled {
  @apply opacity-50;
}

.asio-product-tile__title {
  @apply capitalize font-poppins;
}

.asio-product-tile__img-panel {
  @apply h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-bcolor;
}

.asio-product-tile__img {
  @apply h-full w-full object-cover object-center;
}

.asio-product-tile__details-panel {
  @apply ml-4 flex w-2/6 flex-1 flex-col text-body;
}

.asio-product-tile__actions-panel {
  @apply flex w-2/6 flex-col justify-between text-sm items-end;
}

.asio-product-price-discount__container {
  @apply text-body font-semibold font-poppins flex items-center space-x-4;
}

.asio-product-description__container {
  @apply mb-8 flex flex-col gap-2 justify-start items-start md:mx-0 mx-5;
}

.asio-product-description_container-text-container {
  @apply overflow-hidden transition-[max-height] duration-500 ease-in max-h-28;
  mask-image: linear-gradient(180deg, #000 50%, #0000);
}

.asio-product-description__container-text-container--expanded {
  @apply pb-2 max-h-80 md:max-h-screen;
  mask-image: none;
}

.asio-product-description__container-text {
  @apply font-poppins text-body text-[18px] md:text-[16px] leading-7 text-pretty;
}

.asio-product-description__container-button {
  @apply p-0 font-poppins text-[16px] md:text-[14px] leading-[26px] md:leading-[22px] font-bold;
}

.asio-color-selector__container {
  @apply mb-8 flex flex-col gap-2 md:mx-0 mx-5;
}

.asio-color-selector__label-container {
  @apply flex flex-row space-x-2 items-center mb-1;
}

.asio-color-selector__label {
  @apply text-body font-semibold font-poppins text-[16px];
}

.asio-size-selector__label {
  @apply text-body font-semibold font-poppins text-[16px] mb-1;
}

.asio-size-selector__container {
  @apply mb-4 flex flex-col gap-2 md:mx-0 mx-5;
}

.asio-color-selector__option {
  @apply border-bcolor border-2 h-10 w-10;
}

.asio-selector__option-disabled {
  @apply border-bcolor border-2 h-10 w-10 relative !bg-gray-200;
  &::after {
    @apply content-[''] h-10 w-10 bg-diagonal-strike absolute inset-0 flex;
  }
}

.asio-color-selector__option-disabled {
  @apply border-bcolor border-2 h-10 w-10 relative;
  &::after {
    @apply content-[''] h-10 w-10 rounded-full bg-diagonal-strike absolute inset-[-2px] flex;
  }
}

.asio-color-selector__label-text {
  @apply capitalize font-poppins;
}

.asio-variant-selector__selected {
  @apply ring ring-[#B42573]/50;
}

.asio-variant-selector__disabled {
  @apply opacity-50;
}

.asio-update-cart-submit__button {
  @apply bg-primarybtn w-full h-11 md:mr-5 rounded-sm md:ml-0 mx-5;
}

.asio-update-cart-submit__button > div {
  @apply justify-center flex flex-col items-center;
  &::after {
    @apply flex content-[''] bg-gray-300 h-[2px] w-[30px] mt-0.5;
  }
}
