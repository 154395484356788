/* Hero with Background */
.landing__hero_main_container {
  @apply relative w-full h-full;
}
.landing__hero_main_image_container {
  @apply absolute inset-0;
}
.landing__hero_main_image {
  @apply object-cover absolute;
}
.landing__hero_main_image_overlay {
  @apply w-full h-full absolute bg-black opacity-45;
}
.landing__hero_main_content_container {
  @apply relative text-white font-poppins py-20 md:py-40 px-14 md:pl-52 md:w-[55%] font-semibold text-center md:text-left;
}
.landing__hero_main_eyebrow {
  @apply uppercase text-[#D9D9D9];
}
.landing__hero_main_title {
  @apply text-5xl mt-2 mb-5 leading-[60px];
}
.landing__hero_main_description {
  @apply text-sm;
}
.asio-hero-main__button {
  @apply mt-10 rounded border py-3 px-8 md:px-11 inline-flex flex-col items-center;
  &::after {
    @apply flex content-[''] bg-gray-300 h-[2px] w-[30px] mt-0.5;
  }
}

/* Hero with Eyebrow and Title */
.landing__hero_eyebrow_title_container {
  @apply pt-[72px] pb-9 text-center font-poppins md:space-y-3 font-semibold;
}
.landing__hero_eyebrow_title_eyebrow {
  @apply text-[#B42573] uppercase;
}
.landing__hero_eyebrow_title_title {
  @apply text-[28px] md:text-5xl text-[#0B0F31];
}

/* Hero with Single Panel */
.landing__hero_single_panel_container {
  @apply h-[400px] flex flex-col justify-center font-semibold md:pl-28 text-center md:text-left px-8 md:pr-0;
}
.landing__hero_single_panel_eyebrow {
  @apply text-[#B42573] uppercase;
}
.landing__hero_single_panel_title {
  @apply text-[28px] text-[#0B0F31] mt-2 mb-5;
}
.landing__hero_single_panel_description {
  @apply font-normal md:w-3/4 text-[#0B0F31];
}
.asio-hero-single-panel__button {
  @apply mt-10 w-fit rounded py-3 px-11 bg-[#B42573] text-white mx-auto md:mx-0 flex flex-col items-center;
  &::after {
    @apply flex content-[''] bg-gray-300 h-[2px] w-[30px] mt-0.5;
  }
}

/* Hero with Single Image */
.landing__hero_single_image {
  @apply object-cover w-full h-[400px];
}

/* Hero with Products */
.landing__hero_products_container {
  @apply grid grid-cols-1 md:grid-cols-4 gap-4 pb-[72px];
}
.landing__hero_products_content {
  @apply flex-col;
}
.landing__hero_products_image_container {
  @apply overflow-hidden rounded-md;
}
.asio-item-card__content-image {
  @apply transition ease-in delay-150 hover:scale-110 bg-slate-100 object-cover;
}
.landing__hero_products_header {
  @apply my-3 flex flex-col items-center;
}
.landing__hero_products_title {
  @apply text-body;
}
.landing__hero_products_price {
  @apply mt-3 text-body;
}

/* Hero with Categories */
.landing__hero_categories_container {
  @apply grid grid-cols-1 md:grid-cols-3 gap-4 pb-[72px];
}
.landing__hero_categories_content {
  @apply flex-col;
}
.landing__hero_categories_image_container {
  @apply overflow-hidden rounded-md;
}
.asio-item-card__content-image {
  @apply transition ease-in delay-150 hover:scale-110 bg-slate-100 object-cover;
}
.landing__hero_categories_header {
  @apply my-3 flex flex-col items-center text-center;
}
.landing__hero_categories_title {
  @apply text-body;
}
.landing__hero_categories_description {
  @apply mt-3 text-body;
}

/* Global Messaging Banner */
.landing__global_messaging_banner_container {
  @apply p-3.5 bg-[#B42573];
}
.landing__global_messaging_banner_content {
  @apply font-semibold text-white text-sm text-center;
}

/* Newsletter Form */
.landing__newsletter_form_container {
  @apply bg-[#F8F9FC] md:flex md:justify-center px-14 py-10 md:py-20 md:px-40 w-full md:items-center md:gap-x-20 space-y-5 md:space-y-0;
}
.landing__newsletter_form_title {
  @apply text-3xl md:text-4xl font-semibold text-center md:text-left;
}
.landing__newsletter_form_content {
  @apply flex-1 space-y-2;
}
.landing__newsletter_form_description {
  @apply text-sm text-[#0B0F31] pl-1;
}
