.asio-summary-container {
  @apply bg-slate-50 h-full block;
}

div:has(div.asio-summary-container) {
  @apply h-full;
}

.asio-summary-content {
  @apply p-4 flex flex-col gap-4 box-content;
}

.asio-summary-title__container {
  @apply flex flex-col items-start w-full space-y-2 mb-4;
}

.asio-summary-title__text {
  @apply font-poppins text-[22px] leading-9 font-semibold text-body;
}

.asio-stepper-text {
  @apply font-poppins text-[16px] font-semibold text-primarybtn;
}

.asio-summary-products__container {
  @apply flex-1 space-y-4 overflow-y-auto;
}

.asio-summary-section__container {
  @apply flex flex-col space-y-4 border-t pt-4 text-body font-poppins;
}

.asio-summary-section__content {
  @apply flex items-center justify-between;
}
