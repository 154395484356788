/*      Product Card        */

.asio-item-card {
    @apply hover:cursor-pointer border-bcolor border rounded-md ;
}

.asio-item-card__content {
    @apply flex p-4;
}

.asio-item-card__content-price {
    @apply text-[16px] leading-[22px] md:text-[12px] font-semibold font-poppins;
}

.asio-item-card__content-title {
    @apply font-semibold font-poppins text-[24px] leading-9 md:text-[18px] md:leading-[26px] text-wrap;
}

.asio-item-card__content-image {
    @apply object-contain rounded-md h-64 w-full;
}